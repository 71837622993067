import {
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { CardHeroItemVariants } from './CardHeroItem.types';

const defaultProps: ComponentsProps['CardHeroItem'] = {};

const styleOverrides: ComponentsOverrides<Theme>['CardHeroItem'] = {
  root: ({ theme, ownerState }) => ({
    containerType: 'inline-size',
    width: 'calc(100% - 80px)',
    flex: 1,
    transition: 'all 0.25',

    ...(ownerState?.link && {
      '[class*="CardHeroItem-title"]': {
        textDecoration: 'underline',
        textDecorationColor: 'transparent'
      },
      '&:hover': {
        '[class*="CardHeroItem-title"]': {
          textDecorationColor: 'var(--mui-palette-text-primary)'
        }
      }
    }),

    ...theme.mixins.applyColorScheme({
      ownerState: { ...ownerState, color: ownerState?.color },
      theme
    }),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    willChange: 'transform',

    overflow: 'visible',
    ...(ownerState?.link &&
    (ownerState?.variant === CardHeroItemVariants.media ||
      ownerState?.variant === CardHeroItemVariants.blog)
      ? {
          '[class*=CardHeroItem-CardHeroItemMedia] img': { transition: 'all 0.25s ease-in-out' },
          '&:hover': {
            '[class*=CardHeroItem-CardHeroItemMedia] img': {
              boxShadow: `0px 7px 8px -4px rgba(0,0,0,0.08),0px 12px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.08)`,
              transform: 'translateY(-8px)'
            }
          }
        }
      : {}),
    ...(ownerState?.variant === CardHeroItemVariants.stat && {
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: '8px'
    }),
    ...(ownerState?.variant === CardHeroItemVariants.icon && {
      overflow: 'hidden',
      borderRadius: '8px',
      alignItems: 'center'
    })
  }),

  CardHeroItemMedia: ({ ownerState, theme }) => ({
    'transition': 'all 0.25s ease-in-out',
    'backgroundColor': 'inherit',
    'padding': 0,
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    '> *': {
      objectFit: 'cover',
      width: '100%',
      height: 'auto',
      aspectRatio: '16/9'
    },
    ...(ownerState?.variant === CardHeroItemVariants.stat ||
    ownerState?.variant === CardHeroItemVariants.icon
      ? {
          img: {
            width: 40,
            height: 40
          }
        }
      : null)
  }),

  contentWrap: ({ ownerState, theme }) => ({
    'flex': 1,
    'width': '100%',
    'padding': theme.spacing(10, 0),
    [theme.breakpoints.down('md')]: {
      minHeight: 504
    },

    ...(ownerState?.variant === CardHeroItemVariants.stat && {}),
    'display': 'flex',
    'gap': theme.spacing(2),
    'flexDirection': 'column',
    '[class*=RichText-root]': {
      '> *': { margin: 0 }
    }
  }),

  title: ({ ownerState, theme }) => ({
    '.MuiTypography-root': {
      ...(ownerState?.variant === CardHeroItemVariants.stat && {
        ...theme.typography.h3
      }),

      ...(ownerState?.variant === CardHeroItemVariants.icon && {
        ...theme.typography.h4
      }),

      ...(ownerState?.variant === CardHeroItemVariants.media && {
        ...theme.typography.h5
      }),

      ...(ownerState?.variant === CardHeroItemVariants.blog && {
        ...theme.typography.h5
      }),
      ...(ownerState?.variant === CardHeroItemVariants.media ||
      ownerState?.variant === CardHeroItemVariants.blog
        ? {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            minHeight: 52
          }
        : {})
    }
  }),
  bodyWrap: ({ ownerState, theme }) => ({
    minHeight: 64
  }),
  body: ({ ownerState, theme }) => ({
    '.MuiTypography-root': {
      ...(ownerState?.variant === CardHeroItemVariants.media ||
      ownerState?.variant === CardHeroItemVariants.blog
        ? {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis'
          }
        : {})
    }
  }),

  subtitle: ({ ownerState, theme }) => ({
    '.MuiTypography-root': {
      ...(ownerState?.variant === CardHeroItemVariants.icon && {
        ...theme.typography.h3,
        fontWeight: 400
      }),

      ...(ownerState?.variant === CardHeroItemVariants.stat && {
        ...theme.typography.h4,
        fontWeight: 400
      }),

      ...(ownerState?.variant === CardHeroItemVariants.media && {
        ...theme.typography.body1
      }),

      ...(ownerState?.variant === CardHeroItemVariants.blog && {
        ...theme.typography.body1
      })
    }
  }),

  actionsWrap: ({ theme, ownerState }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    zIndex: 2,
    width: '100%',
    minHeight: 40,
    padding: 0,
    ...(ownerState?.variant === CardHeroItemVariants.stat ||
    ownerState?.variant === CardHeroItemVariants.icon
      ? {
          justifyContent: 'center'
        }
      : null)
  }),

  link: {
    'position': 'absolute',
    'top': 0,
    'left': 0,
    'zIndex': 1,
    'width': '100%',
    'height': '100%',
    '&:hover': {
      '.MuiCardHeroItemActionArea-focusHighlight': {
        opacity: 0
      }
    }
  }
};

const createVariants = (theme: Theme): ComponentsVariants['CardHeroItem'] => [
  {
    props: {
      variant: 'carouselHeroItem'
    },
    style: ({ ownerState }) => ({
      'padding': 0,
      'display': 'grid',
      'gridTemplateColumns': '1fr 1fr',
      'alignItems': 'flex-start',
      'backgroundColor': 'transparent',
      'minHeight': 700,
      '[class*=CardHeroItem-cardHeroItemMedia] > *': {
        '&:nth-child(1)': {
          opacity: 0
        },
        '&:nth-child(2)': {
          transform: 'translateY(0%) translateX(24px) rotateZ(0deg)'
        }
      },
      ...(ownerState?.loaded
        ? {
            '&': {
              [theme.breakpoints.up('xs')]: {
                '[class*=CardHeroItem-cardHeroItemMedia] > *': {
                  '&:nth-child(1)': {
                    transform: 'translateY(4%) translateX(25px) rotateZ(5deg)',
                    opacity: 1
                  },
                  '&:nth-child(2)': {
                    transform: 'translateY(0%) translateX(24px) rotateZ(5deg)'
                  },
                  'transitionDelay': 0,
                  '.swiper-slide-active &': {
                    'opacity': 1,
                    '&:nth-child(1)': {
                      transitionDelay: '0.3s',
                      transform: 'translateY(10%) translateX(-2%) rotateZ(-10deg)'
                    }
                  }
                },
                '[class*=CardHeroItem-contentWrap]': {
                  'transition': 'all 0.4s ease-in-out',
                  'opacity': 0,
                  'transform': 'translateX(-200%)',
                  '.swiper-slide-active &': {
                    opacity: 1,
                    transform: 'translateX(0%)'
                  }
                }
              },
              [theme.breakpoints.up('md')]: {
                '[class*=CardHeroItem-cardHeroItemMedia] > *': {
                  '.swiper-slide-active &': {
                    '&:nth-child(1)': {
                      transitionDelay: '0.3s',
                      transform: 'translateY(10%) translateX(-2%) rotateZ(-10deg)'
                    }
                  }
                }
              },
              [theme.breakpoints.down('md')]: {
                '[class*=CardHeroItem-cardHeroItemMedia] > *': {
                  '.swiper-slide-active &': {
                    'opacity': 1,
                    '&:nth-child(1)': {
                      transitionDelay: '0.3s',
                      transform: 'translateY(10%) translateX(-7%) rotateZ(-10deg)'
                    }
                  }
                }
              }
            }
          }
        : {}),

      '[class*=CardHeroItem-contentWrap]': {
        'order': 1,
        'gridColumn': 1,
        'gridRow': 1,
        'containerType': 'inline-size',
        'width': '100%',
        'backgroundColor': 'transparent',
        'padding': theme.spacing(12, 0),
        'transition': 'all 0.5s ease-in-out',

        'transitionDelay': 0,
        '.swiper-slide-active &': {
          opacity: 1,
          transitionDelay: '0.3s'
        }
      },

      'overflow': 'visible',
      'gap': theme.spacing(10),
      'boxShadow': 'none',
      'borderRadius': 4,
      '[class*="CardHeroItem-title"] .MuiTypography-root': {
        ...theme.typography.h1
      },
      '[class*=CardHeroItem-cardHeroItemMedia]': {
        'zIndex': 9999,
        'gridColumn': 2,
        'position': 'relative',
        'paddingTop': theme.spacing(10),
        '& > *': {
          position: 'absolute',
          top: '100%',
          aspectRatio: '9/21',
          maxWidth: 530,
          objectFit: 'contain',
          objectPosition: 'top',
          width: 'calc(100% - 48px)',
          display: 'inline-block',
          transition: 'all 0.3s ease-in-out'
        }
      },

      [theme.breakpoints.down('md')]: {
        'maxHeight': '100vh',
        'minHeight': 400,
        'gap': theme.spacing(3, 0),
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'flex-start',
        '[class*=CardHeroItem-contentWrap]': {
          padding: theme.spacing(3, 0),
          flex: 'unset'
        },

        '[class*=CardHeroItem-cardHeroItemMedia]': {
          'overflow': 'visible',
          'height': 200,
          'width': '100%',
          'transform': 'translateY(-40px)',
          'position': 'relative',
          'order': 2,
          '& > *': {
            position: 'absolute',
            top: '0',
            aspectRatio: 'unset',
            maxWidth: 530,
            objectFit: 'contain',
            objectPosition: 'top',
            width: 'calc(100% - 120px)',
            display: 'inline-block',
            transform: 'unset'
          }
        }
      }
    })
  }
];

export const CardHeroItemTheme = (theme: Theme): ThemeOptions => ({
  components: {
    CardHeroItem: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default CardHeroItemTheme;
