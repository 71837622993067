import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { CollectionVariants } from './Collection.types';

const defaultProps: ComponentsProps['Collection'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Collection'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),

    containerType: 'inline-size',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    gap: theme.spacing(2)
  }),
  actionsWrap: ({ theme }) => ({
    display: 'flex',
    // paddingTop: theme.spacing(3),
    gap: theme.spacing(2),
    justifyContent: 'center'
  }),
  itemsGrid: ({ theme, ownerState }) => ({
    gridColumn: 'content-start/content-end',
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',

    ...(ownerState?.variant === CollectionVariants.twoPerRow && {
      'display': 'flex',
      'flexWrap': 'wrap',
      'justifyContent': 'center',
      '.MuiPaper-root': {
        flex: '0 0 100%'
      },
      [theme.containerBreakpoints.up('sm')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(50% - 12px)'
        }
      }
    }),
    ...(ownerState?.variant === CollectionVariants.threePerRow && {
      'display': 'flex',
      'flexWrap': 'wrap',
      'justifyContent': 'center',
      '.MuiPaper-root': {
        flex: '0 0 100%'
      },
      [theme.containerBreakpoints.up('sm')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(50% - 12px)'
        }
      },
      [theme.containerBreakpoints.up('md')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(33.3333% - 16px)'
        }
      }
    }),

    ...(ownerState?.variant === CollectionVariants.fourPerRow && {
      'display': 'flex',
      'flexWrap': 'wrap',
      'justifyContent': 'center',
      '.MuiPaper-root': {
        flex: '0 0 100%'
      },
      [theme.containerBreakpoints.up('sm')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(50% - 12px)'
        }
      },
      [theme.containerBreakpoints.up('md')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(25% - 18px)'
        }
      }
    }),

    ...(ownerState?.variant === CollectionVariants.fivePerRow && {
      'display': 'flex',
      'flexWrap': 'wrap',
      'justifyContent': 'center',
      '.MuiPaper-root': {
        flex: '0 0 100%'
      },
      [theme.containerBreakpoints.up('sm')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(50% - 12px)'
        }
      },
      [theme.containerBreakpoints.up('md')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(25% - 18px)'
        }
      },
      [theme.containerBreakpoints.up('lg')]: {
        '.MuiPaper-root': {
          flex: '0 0 calc(20% - 20px)'
        }
      }
    })
  }),

  pagination: ({ theme }) => ({
    justifySelf: 'center',
    padding: theme.spacing(3, 0)
  }),
  contentGrid: ({ theme }) => ({
    rowGap: theme.spacing(3)
  }),
  disclaimerText: ({ theme }) => ({
    textAlign: 'center'
  })
};

const createVariants = (theme: Theme): ComponentsVariants['Collection'] => [];

const collectionTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Collection: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default collectionTheme;
