import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Quote'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Quote'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({
      ownerState: {
        ...ownerState,
        color: ownerState?.color
      },
      theme
    }),
    backgroundColor: 'transparent',
    containerType: 'inline-size',
    height: '100%'
  }),

  contentOuterGrid: ({ theme }) => ({
    'height': '100%',
    'alignItems': 'center',
    '&': {
      rowGap: `${theme.spacing(3)}!important`
    }
  }),

  actionsWrap: ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center'
  }),

  quoteText: ({ theme, ownerState }) => ({
    'gridRow': 1,
    'gridColumn': 'content-start/content-end',
    'textAlign': 'center',
    'display': 'inline',
    '*': {
      color: ownerState?.color ? 'var(--variant-highlight-color)' : undefined,
      display: 'inline'
    },
    '.MuiTypography-root, *': {
      fontSize: theme.typography.h5.fontSize
    },

    [theme.containerBreakpoints.up('sm')]: {
      'gridColumn': 'content-start/content-end',
      '.MuiTypography-root, *': {
        fontSize: theme.typography.h3.fontSize
      }
    }
  }),

  quoteSymbol: ({ ownerState }) => ({
    color: ownerState?.color ? 'var(--variant-highlight-color)' : undefined
  }),

  authorDetails: ({ theme, ownerState }) => ({
    ...(ownerState?.image
      ? { display: 'flex', flexDirection: 'column', gap: theme.spacing(0) }
      : { textAlign: 'center' })
  }),
  authorRoot: ({ theme, ownerState }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    ...(!ownerState?.image ? { flexDirection: 'column', alignItems: 'center' } : {})
    // alignItems: 'center',
    // display: 'grid',
    // gridColumnGap: theme.spacing(3)
    // gridTemplateColumns: '1fr 1fr',
    // gridTemplateAreas: `
    //     "image authorName"
    //     "image authorTitle"
    // `
  }),

  image: ({ theme }) => ({
    width: 56,
    height: 56,
    borderRadius: '50%',
    objectFit: 'cover'
  }),
  logo: ({ theme }) => ({
    height: 64,
    width: 'auto',
    maxWidth: '200px!important',
    margin: 'auto',
    marginTop: theme.spacing(1),
    objectFit: 'contain'
  }),

  authorName: ({ theme }) => ({
    '> *': { fontWeight: 'bold' }
  }),

  authorTitle: ({ theme }) => ({
    '*': {}
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['Quote'] => [];

export const quoteTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Quote: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default quoteTheme;
