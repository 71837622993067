import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['HeaderNavGroup'] = {};

const styleOverrides: ComponentsOverrides<Theme>['HeaderNavGroup'] = {
  root: ({ theme, ownerState }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'gap': theme.spacing(2),

    /// Done here instead of each component to prevent race conditions
    // This really targets nestedness

    '[class*=HeaderNavGroup-navItemSubMenu]': {
      'gap': 0,
      'marginTop': theme.spacing(-3),
      'marginLeft': theme.spacing(-3),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(-1),
        marginLeft: theme.spacing(-1),
        gap: theme.spacing(2)
      },
      '[class*=HeaderNavGroup-navItemSubMenu]': {
        margin: 0,
        gap: theme.spacing(2)
      }
    },
    '[class*="HeaderNavLink-navItemSubMenuGrid"]': {
      // display: 'flex',
      [theme.breakpoints.up('md')]: {
        visibility: 'hidden',
        zIndex: -2,
        transform: 'translateY(-100%)'
      }
    },
    '[class*=HeaderNavGroup-navItemSubMenuItem]': {
      // 'border': '1px solid green',
      'padding': 0,
      'paddingLeft': theme.spacing(3),
      'paddingTop': theme.spacing(3),
      '[class*=HeaderNavGroup-navItemSubMenuItem]': {
        padding: 0,
        // border: '1px solid blue',
        ...theme.typography.overline,
        margin: 0,
        textTransform: 'unset'
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1)
      }
    }
  }),
  navItemLinkGroup: { fontWeight: 'bold' },
  navItemLink: ({ theme }: { theme: Theme }) => ({
    padding: 0,
    border: 'none',
    // 'flexGrow': '1',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    cursor: 'pointer'
    // '&:is(:hover, :focus-within):not(:focus-visible)': {,
  }),
  navItemSubMenu: ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 1fr)', // Change this to however many columns they'd like
      gap: theme.spacing(1)
    },
    gridTemplateColumns: '1fr',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: "auto"
    }
  }),

  navItemSubMenuItem: ({ theme, ownerState }) => ({
    '[class*=HeaderNavGroup-navItemSubMenuItem]': {
      // border: '1px solid red'
    },
    // 'padding': theme.spacing(1),
    // 'paddingRight': 0,
    // 'margin': theme.spacing(5),
    // 'border': '2px solid black',

    // '[class*=HeaderNavGroup-navItemSubMenuItem]': {
    //   padding: 0,
    //   // border: '1px solid blue',
    //   ...theme.typography.overline,
    //   margin: 0,
    //   textTransform: 'unset'
    // },
    // '& &': {
    //   'margin': 0,
    //   '&': {
    //     margin: theme.spacing(5)
    //   }
    // },

    '[class*=HeaderNavGroup-navItemGroup]': { fontWeight: 400 },
    [theme.breakpoints.up('md')]: {
      width: 'fit-content'
    }
  }),

  navItemGroup: ({ theme }) => ({
    [theme.breakpoints.only('md')]: {
      paddingRight: 0,
      paddingLeft: 0
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['HeaderNavGroup'] => [
  {
    props: { variant: 'featured' },
    style: {
      gridColumnEnd: 'content-end',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      width: '100%',
      flex: 1
    }
  }
];

export const headerNavGroupTheme = (theme: Theme): ThemeOptions => ({
  components: {
    HeaderNavGroup: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default headerNavGroupTheme;
