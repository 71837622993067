import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const SITEMESSAGE_TIMEOUT = '200s';

const defaultProps: ComponentsProps['SiteMessage'] = {};

const styleOverrides: ComponentsOverrides<Theme>['SiteMessage'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),

    position: 'relative',
    padding: 'calc(var(--grid-gap) / 2)'
  }),

  contentWrap: ({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap'
    }
  }),

  icon: ({ theme }) => ({
    width: theme.spacing(4),
    height: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },

    img: {
      objectFit: 'contain',
      width: 'inherit',
      height: 'inherit'
    }
  }),

  closeIconWrap: ({ theme }) => ({
    'position': 'absolute',
    'right': 'calc(var(--grid-gap) / 4)',
    'top': 'calc(var(--grid-gap) / 4)',
    'color': 'var(--mui-palette-text-primary)',

    '& *': {
      color: 'currentColor !important'
    }
  }),

  linkWrap: ({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap'
    }
  }),

  // link: : {},

  desktopText: ({ ownerState, theme }) => ({
    ...(!!ownerState?.text &&
      !!ownerState?.mobileText && {
        [theme.breakpoints.down('md')]: {
          display: 'none !important'
        }
      })
  }),

  mobileText: ({ ownerState, theme }) => ({
    ...(!!ownerState?.text &&
      !!ownerState?.mobileText && {
        [theme.breakpoints.up('md')]: {
          display: 'none !important'
        }
      }),

    textAlign: 'center'
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['SiteMessage'] => [];

export const siteMessageTheme = (theme: Theme): ThemeOptions => ({
  components: {
    SiteMessage: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default siteMessageTheme;
