import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['SearchPage'] = {};

const styleOverrides: ComponentsOverrides<Theme>['SearchPage'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    minHeight: '50vh',
    padding: theme.spacing(4, 0),
    containerType: 'inline-size',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',

    ...(ownerState?.prevBgColor && ownerState.inheritTopBGOverlap
      ? {
          'paddingTop': 0,
          'paddingLeft': 0,
          'paddingRight': 0,
          '&::before': {
            content: '""',
            position: 'absolute',
            display: 'block',
            height: 'var(--section-padding)',
            width: '100%',
            ...theme.mixins.applyColorScheme({
              ownerState: { ...ownerState, backgroundColor: ownerState?.prevBgColor ?? 'navy' },
              theme
            })
          }
        }
      : { ...theme.mixins.applyColorScheme({ ownerState, theme }) })
  }),

  searchBoxWrap: ({ theme, ownerState }) => ({
    gridColumn: 'three-start/content-end!important',
    gridRow: 1,

    [theme.breakpoints.down('md')]: {
      gridColumn: 'content-start/content-end!important',
      gridRow: 'auto'
    }
  }),
  filtersOuterWrap: ({ theme, ownerState }) => ({
    gridColumnStart: 'start',
    gridColumnEnd: 'end',
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--grid-gap)',
    gridRow: 1,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gridColumn: 'start/three-end'
    }
  }),

  currentRefinementsWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--grid-gap)'
  },

  filtersWrap: ({ theme, ownerState }) => ({
    gridColumn: 'content-start/three-end!important',
    gridRow: '1/3',
    // ...(ownerState?.filtersPlacement === 'left'
    //   ? {
    position: 'sticky',
    top: 0,
    //       // flexDirection: 'column',
    //       // gap: 'var(--grid-gap)',
    //       // gridRow: 2
    //     }
    //   : {
    //       display: 'contents'
    //     })
    [theme.breakpoints.down('md')]: {
      gridColumn: 'content-start/content-end!important',
      gridRow: 'auto',
      position: 'relative'
    }
  }),

  resultsWrap: ({ theme, ownerState }) => ({
    gridColumn: 'four-start/content-end!important',
    gap: theme.spacing(2),
    gridRow: 2,

    display: 'flex',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'content-start/content-end!important',
      gridRow: 'auto'
    },
    flexDirection: 'column',
    [theme.containerBreakpoints.up('md')]: {
      gridRow: 2,
      gridColumn: 'three-start/content-end'
      // ...theme.mixins.applyColorScheme({ ownerState, backgroundColor: 'white' })
    }
  }),

  itemsGrid: ({ theme, ownerState }) => ({
    // 'display': 'flex',
    // 'flexDirection': 'column',
    // 'gap': theme.spacing(2),
    // '& [class*=ais-Hit]': {
    //   display: 'contents'
    // }
  }),
  paginationWrap: ({ theme, ownerState }) => ({
    display: 'flex',
    justifyContent: 'center'
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['SearchPage'] => [];

const collectionDynamicTheme = (theme: Theme): ThemeOptions => ({
  components: {
    SearchPage: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default collectionDynamicTheme;
