import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { CardPricingVariants } from './CardPricing.types';

const defaultProps: ComponentsProps['CardPricing'] = {};

const styleOverrides: ComponentsOverrides<Theme>['CardPricing'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    containerType: 'inline-size'
  }),

  cardPricingWrap: ({ theme, ownerState }) => ({
    'background': theme.vars.palette.primary.main,

    '.MuiTypography-root': {
      color: theme.vars.palette.white.main,
      textAlign: 'center'
    },

    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'flex-start',
    'gap': theme.spacing(1),
    'alignItems': 'center',
    'minHeight': 248,
    'padding': theme.spacing(6, 7)
  }),

  contentWrap: ({ ownerState, theme }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'padding': theme.spacing(6, 7),
    'gap': theme.spacing(3),
    '[class*="CardPricing-body"] .MuiTypography-root': {
      color: theme.vars.palette.black.main
    }
  }),

  price: { '> .MuiTypography-root': { margin: 0 } },

  overline: { margin: 0 },

  textBelowPrice: ({ theme }) => ({
    '*:not(:last-child):is(p)': {
      marginBottom: theme.spacing(0)
    }
  }),

  disclaimerText: {
    textAlign: 'center'
  },
  actionsWrap: ({ theme }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'padding': 0,
    'gap': theme.spacing(2),
    '> ': {
      marginLeft: '0!important'
    }
  })
};

const createVariants = (theme: Theme): ComponentsVariants['CardPricing'] => [];

export const cardTheme = (theme: Theme): ThemeOptions => ({
  components: {
    CardPricing: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default cardTheme;
