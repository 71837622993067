import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['HeaderNavLink'] = {};

const menuMobileBreakpoint = 'lg';

const styleOverrides: ComponentsOverrides<Theme>['HeaderNavLink'] = {
  root: ({ theme, ownerState }) => ({
    // 'padding': theme.spacing(4, 0),
    // 'borderBottom': `solid ${theme.spacing(0.5)} transparent`,
    // 'borderTop': `solid ${theme.spacing(0.5)} transparent`,
    'display': 'flex',
    'flexDirection': 'column',
    'height': '100%',
    'justifyContent': 'center',
    // 'flexGrow': '1',
    'position': 'relative',

    [theme.breakpoints.down(menuMobileBreakpoint)]: {
      'width': '100%',
      '[class*="HeaderNavLink-navItemSubMenu-"]': {
        padding: 0,
        gridColumn: 'full-start/full-end'
      }
    },

    '[class*="HeaderNavLink-navItemSubMenuGrid"]': {
      // display: 'flex',
      visibility: 'hidden',
      zIndex: -2,
      transform: 'translateY(-100%)',
      [theme.breakpoints.down(menuMobileBreakpoint)]: {
        zIndex: 0,
        transform: 'none',
        maxHeight: 0,
        padding: 0,
        ...(ownerState.open
          ? {
              '[class*="HeaderNavLink-navItemLink"]': {
                // TODO: Standardize this across the header links if they're the same
                '.MuiSvgIcon-root': {
                  // fill: 'currentcolor',
                  transform: 'rotate(-90deg)'
                }
              },
              'padding': theme.spacing(2, 0),
              'visibility': 'visible',
              'zIndex': -2,
              'opacity': 1,
              'transform': 'translateY(0%)',
              [theme.breakpoints.down(menuMobileBreakpoint)]: {
                zIndex: 0,
                maxHeight: '100vh'
              }
            }
          : null)
      }
    },

    '.open-subnav': {
      visibility: 'hidden',
      zIndex: -2,
      transform: 'translateY(-100%)',
      [theme.breakpoints.down(menuMobileBreakpoint)]: {
        '[class*="HeaderNavLink-navItemLink"]': {
          // TODO: Standardize this across the header links if they're the same
          '.MuiSvgIcon-root': {
            // fill: 'currentcolor',
            transform: 'rotate(-90deg)'
          }
        },
        'padding': theme.spacing(2, 0),
        'visibility': 'visible',
        'zIndex': -2,
        'opacity': 1,
        'transform': 'translateY(0%)',
        [theme.breakpoints.down(menuMobileBreakpoint)]: {
          zIndex: 0,
          maxHeight: '100vh'
        }
      }
    },

    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      '&:hover': {
        '[class*="HeaderNavLink-navItemLink"]': {
          // TODO: Standardize this across the header links if they're the same
          '.MuiSvgIcon-root': {
            // fill: 'currentcolor',
            transform: 'rotate(-90deg)'
          }
        },

        '[class*="HeaderNavLink-navItemSubMenuGrid"]': {
          // display: 'flex',
          visibility: 'visible',
          zIndex: -2,
          opacity: 1,
          transform: 'translateY(0%)',
          [theme.breakpoints.down(menuMobileBreakpoint)]: {
            zIndex: 0,
            maxHeight: '100vh'
          }
        }
      }
    }
  }),

  navItemLink: ({ theme, open }) => ({
    // TODO: Custom Styles
    // 'borderTop': `solid 1px ${theme.vars.palette.primary.main}`,

    // 'flexGrow': '1',
    // 'alignItems': 'center',
    // 'display': 'flex',
    // 'width': '100%',
    // 'justifyContent': 'space-between',
    'cursor': 'pointer',

    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      // padding: theme.spacing(1),
      justifyContent: 'center'
      // borderTop: 'none'
    },

    // TODO: Standardizxe this across the header links if they're the same
    '.MuiSvgIcon-root': {
      fill: theme.vars.palette.primary.main,
      width: 'auto',
      height: theme.spacing(2),
      marginLeft: theme.spacing(0.5),
      transform: 'rotate(90deg)',
      transition:
        'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      ...(!!open && {
        [theme.breakpoints.down(menuMobileBreakpoint)]: {
          transform: 'rotate(-90deg)'
        }
      }),

      [theme.breakpoints.up(menuMobileBreakpoint)]: {
        height: '10px',
        fill: theme.vars.palette.primary.main
      }
    }
  }),

  navItemSubMenuGrid: ({ theme, open, ownerState }) => ({
    // display: 'none',
    transition: '.25s ease-in-out',
    // ...theme.mixins.applyColorScheme({ ownerState, theme }),
    backgroundColor: theme.vars.palette.background.lightThree,
    // ...theme.mixins.applyGrid({ theme, ownerState: { overrideNested: true } }),
    [theme.breakpoints.down(menuMobileBreakpoint)]: {
      display: 'flex',
      flexDirection: 'column',
      background: 'transparent',
      padding: theme.spacing(2, 0)

      // gap: theme.spacing(2),
    },
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      transitionDelay: '0s',
      // visibility: 'hidden',
      // opacity: 0,

      // display: 'grid',
      // gap: theme.spacing(0),
      // overflow: 'hidden',

      width: '100vw',
      // height: '80px',
      position: 'fixed',
      top: 80,
      left: 0,
      // @ts-ignore: TODO: items not recognized
      // gridTemplateColumns: `repeat(${(ownerState.numOfCols ?? 0) + 1}, auto)`,
      // border: `solid 1px ${theme.vars.palette.primary.contrastText}`,
      zIndex: 1
      // transform: 'translateY(100%)',
      // padding: theme.spacing(3, 0, 0, 0),

      // ...(ownerState.numOfCols === 2 && {
      //   // TODO: Check variant here instead?
      //   right: ownerState.hasMegaNav ? -578 : -252
      // }),

      // ...(ownerState.numOfCols === 1 && {
      //   left: 0
      // })
    }
  }),
  navItemSubMenu: ({ theme, open, ownerState }) => ({
    // display: 'none',
    // transition: 'visibility 0s, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // ...theme.mixins.applyColorScheme({ ownerState, theme }),
    // backgroundColor: theme.vars.palette.background.lightThree,
    // // ...theme.mixins.applyGrid({ theme, ownerState: { overrideNested: true } }),
    // [theme.breakpoints.down('md')]: {
    //   flexDirection: 'column'
    // },

    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    gridColumnStart: 'three-start',

    // gridColumnEnd: 'three-end',
    [theme.breakpoints.up('sm')]: {
      // gridColumnEnd: 'five-end'
    },
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      flexDirection: 'row',
      flex: 1,
      // visibility: 'hidden',
      // opacity: 0,
      // gridColumn: 'content-start/content-end',
      gap: theme.spacing(5),
      // margin: theme.spacing(0, -2),
      padding: theme.spacing(3, 0),
      justifyContent: 'flex-start'
      // gridColumnEnd: 'nine-end'

      // overflow: 'hidden',

      // height: '80px',
      // position: 'fixed',
      // top: 80,
      // left: 0,
      // @ts-ignore: TODO: items not recognized
      // gridTemplateColumns: `repeat(${(ownerState.numOfCols ?? 0) + 1}, auto)`
      // border: `solid 1px ${theme.vars.palette.primary.contrastText}`,
      // zIndex: 1,
      // transform: 'translateY(100%)',
      // padding: theme.spacing(3, 0, 0, 0),

      // ...(ownerState.numOfCols === 2 && {
      //   // TODO: Check variant here instead?
      //   right: ownerState.hasMegaNav ? -578 : -252
      // }),

      // ...(ownerState.numOfCols === 1 && {
      //   left: 0
      // })
    }
  }),

  navItemSubMenuItem: ({ theme, ownerState }) => ({
    // border: '1px solid red',
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      'width': 'auto',
      'alignItems': 'flex-start',
      'position': 'relative',
      '&:not(:last-child):after': {
        content: '""',
        position: 'absolute',
        width: '1px',
        height: '100%',
        bottom: '0',
        right: theme.spacing(-2.5),
        background: theme.vars.palette.background.lightOne
      },
      ...(ownerState?.variant === 'featured'
        ? {
            flex: 1
          }
        : {})
    }
  }),

  megaNavContainer: ({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      juistifyContent: 'space-between',
      // TODO: Is there a better way to define this?
      width: 324
    }
  }),

  megaNavContent: ({ theme }) => ({
    flex: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5)
  }),

  // megaNavTitle: : {},

  // megaNavActions: : {},

  // megaNavAction: : {},

  megaNavMedia: {
    // TODO: Is there a better way to define this?
    maxHeight: 190,
    margin: 0
  }
};

const createVariants = (_theme: Theme): ComponentsVariants['HeaderNavLink'] => [];

export const headerNavLinkTheme = (theme: Theme): ThemeOptions => ({
  components: {
    HeaderNavLink: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default headerNavLinkTheme;
