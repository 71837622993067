import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Page'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Page'] = {
  root: ({ ownerState }) => ({
    '& > :is([class*=Form-root])': {
      marginTop: 'var(--section-padding)',
      marginBottom: 'var(--section-padding)'
    },

    '& > :is([class*=Accordion-root],[class*=Collection-root],[class*=Quote-root],[class*=Section-root],[class*=Block-root],[class*=Carousel-root],[class*=Text-root],[class*=ChatFullPage-root])':
      {
        paddingTop: 'var(--section-padding)',
        paddingBottom: 'var(--section-padding)'
      },

    '& ~  button': {
      ...(!!ownerState?.hideZendesk && { display: 'none' })
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['Page'] => [];

export const pageTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Page: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default pageTheme;
