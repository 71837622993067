import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const SUPERNAV_TIMEOUT = '15s';

const menuMobileBreakpoint = 'lg';

const defaultProps: ComponentsProps['Header'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Header'] = {
  root: ({ theme, ownerState }) => ({
    'position': 'sticky',
    'zIndex': 99999,
    'top': 0,
    'left': 0,
    'width': '100%',
    'height': 60,
    'flexDirection': 'column',
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      height: 80,
      display: 'flex',
      // 'padding': 'var(--grid-gap) 0',
      alignItems: 'center'
    },

    'boxShadow': '0px 0px 20px 0px rgba(31, 31, 31, 0.06)',
    // ':is(&, & [class*=navItemSubMenu])': {
    'backgroundColor': 'white',
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    // },
    '[class*=MuiLink-root] ': {
      'color': 'black',
      '&:hover': {
        color: theme.vars.palette.primary.main
      },
      '&[href$="#"]': {
        textDecoration: 'none!important',
        color: 'var(--mui-palette-text-primary)!important',
        cursor: 'default'
      }
    },
    '[class*=headerMenuNavLink]:hover': {
      color: `${theme.vars.palette.primary.main}!important`,
      textDecoration: 'underline!important',
      cursor: 'pointer!important'
      // backgroundColor: 'var(--current-color-main)'
    },
    '& *': {
      whiteSpace: 'nowrap'
    }
  }),

  contentOuterGrid: ({ theme }) => ({
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      height: ' 100%'
    },
    [theme.breakpoints.down(menuMobileBreakpoint)]: {
      backgroundColor: 'white',

      rowGap: 0
    }
  }),

  logoRoot: ({ theme }) => ({
    gridColumn: 'content-start / content-half',
    gridRow: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: '120px',
    height: 'auto',
    display: 'block',

    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      gridColumn: 'content-start / span 2'
    }
  }),

  siteMessageWrap: {
    width: '100%'
  },

  // logo: {},

  headerMenuCtas: ({ theme }) => ({
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
    justifySelf: 'flex-end',
    gridRow: 3,
    [theme.breakpoints.down(menuMobileBreakpoint)]: {
      '> *:first-child': {
        display: 'none'
      },
      'justifyContent': 'center',
      'flexDirection': 'column',
      'gap': theme.spacing(1),
      '*, [class*=Link-root]': {
        width: '100%!important',
        textAlign: 'center'
      }
    },
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      gridColumnStart: 'eleven-start',
      gridColumnEnd: 'content-end',
      justifyContent: 'flex-end',
      width: '100%',
      gap: theme.spacing(2),
      gridRow: 1
    }
  }),

  headerMobileNavWrap: ({ theme, menuVisible }) => ({
    gridRow: 2,
    gridColumnStart: 'content-start',
    gridColumnEnd: 'content-end',
    maxHeight: '0',
    overflow: 'hidden',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // borderBottom: `solid 2px ${theme.vars.palette.primary.main}`,
    paddingTop: 'var(--grid-gap)',
    paddingBottom: 'var(--grid-gap)',
    gap: theme.spacing(2),

    [theme.breakpoints.down(menuMobileBreakpoint)]: {
      transition: '500ms ease',
      ...(!menuVisible
        ? {
            maxHeight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            borderBottomColor: 'transparent'
          }
        : {
            maxHeight: '100vh'
          })
    },

    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      display: 'contents'
    }
  }),

  headerMenuCtaItem: {
    padding: 0,
    justifyContent: 'center',
    width: 'unset'
  },

  headerMenuNav: ({ theme, ownerState }) => ({
    'justifyItems': 'center',
    'justifyContent': 'flex-end',
    'position': 'unset',
    'display': 'inline-flex',

    '& a': {
      whiteSpace: 'nowrap'
    },
    'gridColumnStart': 'three-start',
    'gridColumnEnd': 'three-end',
    [theme.breakpoints.up('sm')]: {
      gridColumnEnd: 'eight-end',
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      gridColumnEnd: 'eight-end'
    },
    [theme.breakpoints.down('xxl')]: {
      justifyContent: 'flex-start',
      height: 'auto',
      overflow: 'unset',
      maxHeight: '100%',
      gridRow: 1
      // gridColumnStart: 'three-start',
      // gridColumnEnd: ownerState.hasCtaItems ? 'ten-start' : 'content-end',
    }
  }),

  iconButtonWrap: ({ theme }) => ({
    'padding': 0,
    'display': 'flex',
    'gridColumnStart': 'content-half',
    'gridColumnEnd': 'content-end',
    'gridRow': 1,
    'justifyContent': 'flex-end',

    '& > *': {
      paddingTop: 0,
      paddingBottom: 0
    },

    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      display: 'none'
    }
  }),

  // iconButton: : {},

  menuIcon: ({ menuVisible }) => ({
    display: menuVisible ? 'none' : 'block',
    fontSize: 42
  }),

  closeIcon: ({ menuVisible }) => ({
    display: !menuVisible ? 'none' : 'block',
    fontSize: 42
  }),

  headerMenuNavItems: ({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: 0,
    position: 'unset',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    // gap: theme.spacing(2),

    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      // gap: theme.spacing(5),
      height: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between'
      // width: 'auto'
      // 'marginLeft': 'unset',

      // '& > *:last-child a': {
      // paddingRight: 0
      // }
    }
  }),

  headerMenuNavItem: ({ theme }) => ({
    padding: 0,
    position: 'unset',
    width: 'auto',
    [theme.breakpoints.up(menuMobileBreakpoint)]: {
      height: '100%'
    },
    [theme.breakpoints.down(menuMobileBreakpoint)]: {
      justifyContent: 'flex-start',
      // height: '100%'
      width: '100%',
      padding: theme.spacing(1, 0),
      fontWeight: 'bold',
      borderBottom: '1px solid',
      // borderTop: '1px solid',
      borderColor: theme.vars.palette.background.lightOne
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['Header'] => [];

export const headerTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Header: {
      // @ts-expect-error
      height: 80,
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default headerTheme;
