import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['InlineNavigation'] = {};

const styleOverrides: ComponentsOverrides<Theme>['InlineNavigation'] = {
  root: ({ ownerState, theme }) => ({
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.vars.palette.background.lightOne}`,
    // borderTop: `1px solid ${theme.vars.palette.background.lightOne}`,
    transition: '.25 ease-in-out',

    backgroundColor: theme.vars.palette.white.main,
    zIndex: 1100,
    position: 'sticky',
    ...(ownerState.trigger ? {} : {}),

    [theme.breakpoints.up('md')]: {
      top: 80
    },
    [theme.breakpoints.down('md')]: {
      'top': 60,
      'padding': theme.spacing(0, 0),
      'rowGap': 0,
      '[class*=InlineNavigation-text]': {
        display: 'block',
        ...theme.typography.h5,
        margin: 0,
        padding: theme.spacing(2, 0)
      },
      '[class*=linksWrap]': {
        'overflow': 'hidden',
        'transition': '.3s ease-in-out',
        'justifyContent': 'center',
        ...(ownerState?.menuVisible
          ? {
              maxHeight: '70vh'
            }
          : {
              maxHeight: 0
            }),
        '[class*=InlineNavigation-text]': { display: 'none' }
      },
      '[class*=InlineNavigation-link-]': {
        justifyContent: 'flex-start',
        width: 'calc(100% + 16px)',
        margin: theme.spacing(0, -1),
        padding: theme.spacing(1)
      }
    }
  }),
  menuWrap: ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }),
  text: ({ theme }) => ({ display: 'none' }),
  linksWrap: ({ ownerState, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center'
    }
  }),
  link: ({ ownerState, theme }) => ({
    // padding: theme.spacing(1, 2)
    '[class*=Link-linkText]': {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      wordBreak: 'break-all'
    },
    ...(ownerState?.active
      ? {
          color: theme.vars.palette.primary.main
        }
      : null)
  })
};

const createVariants = (theme: Theme): ComponentsVariants['InlineNavigation'] => [
  {
    props: { variant: 'inlineNavigation' },
    style: {
      [theme.breakpoints.up('md')]: {
        '[class*=InlineNavigation-linksWrap]': {
          gap: theme.spacing(2)
        }
      }
    }
  },
  {
    props: { variant: 'tableOfContents' },
    style: {
      [theme.breakpoints.up('md')]: {
        'position': 'relative',
        'top': 0,
        'backgroundColor': 'unset',

        'padding': 0,
        'border': 0,
        '&:before': {
          zIndex: '-1',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '50%',
          background: `var(--mui-palette-prev-color)`
        },
        '[class*=InlineNavigation-link]': {
          'textAlign': 'center',
          'color': theme.vars.palette.common.black,

          '&:hover': {
            color: theme.vars.palette.primary.main
          },
          'padding': theme.spacing(2, 3),
          '*': {
            // May be different on other variants
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis'
          }
        },
        '[class*=InlineNavigation-text]': {
          gridColumn: '1/-1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
          borderBottom: '1px solid grey',
          borderColor: theme.vars.palette.background.lightOne,
          textTransform: 'uppercase',
          color: theme.vars.palette.primary.main,
          margin: 0
        },
        '[class*=InlineNavigation-linksWrap]': {
          margin: 'auto',
          maxWidth: 960,
          padding: 0,
          background: theme.vars.palette.background.lightTwo,
          borderRadius: 24,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, minmax(min-content, 1fr))',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, minmax(min-content, 1fr))'
          },
          [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, minmax(min-content, 1fr))'
          },
          gap: 0
        }
      }
    }
  }
];

export const InlineNavigationTheme = (theme: Theme): ThemeOptions => ({
  components: {
    InlineNavigation: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default InlineNavigationTheme;
