import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { CardVariants } from './Card.types';

const defaultProps: ComponentsProps['Card'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Card'] = {
  root: ({ theme, ownerState }) => ({
    containerType: 'inline-size',
    width: '100%',
    // height: '100%',
    flex: 1,
    transition: 'all 0.25',
    ...(ownerState?.link && {
      '[class*="Card-title"]': {
        textDecoration: 'underline',
        textDecorationColor: 'transparent'
      },
      '&:hover': {
        '[class*="Card-title"]': {
          textDecorationColor: 'var(--mui-palette-text-primary)'
        }
      }
    }),

    ...(!!ownerState?.color &&
      ownerState?.color !== 'inherit' &&
      !!ownerState?.variant &&
      ownerState?.variant !== CardVariants.media && {
        ...theme.mixins.applyColorScheme({
          ownerState: { ...ownerState, color: ownerState?.color },
          theme
        })
      }),

    ...(!ownerState?.color &&
      !!ownerState?.variant &&
      ownerState?.variant !== CardVariants.media && {
        '*:is(*,.MuiTypography-root):not(a)': {
          color: theme.vars.palette.cardBackgroundContrastText
        }
      }),
    /// CARD WRAP

    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    willChange: 'transform',

    // boxShadow: theme.vars.shadows[0],
    gap: theme.spacing(2),

    overflow: 'visible',
    // transform: 'scale(1)'
    ...(ownerState?.link &&
    (ownerState?.variant === CardVariants.media ||
      ownerState?.variant === CardVariants.mediaCentered ||
      ownerState?.variant === CardVariants.blog)
      ? {
          '[class*=Card-cardMedia] img': { transition: 'all 0.25s ease-in-out' },
          '&:hover': {
            '[class*=Card-cardMedia] img': {
              // TODO: boxShadow: theme.vars.shadows[12],
              boxShadow: `0px 7px 8px -4px rgba(0,0,0,0.08),0px 12px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.08)`,
              transform: 'translateY(-8px)'
            }
          }
        }
      : {}),

    ...(ownerState?.variant === CardVariants.stat && {
      // padding: theme.spacing(2, 0),
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: '8px'
    }),

    ...(ownerState?.variant === CardVariants.icon && {
      overflow: 'hidden',
      borderRadius: '8px',
      alignItems: 'center'
      // boxShadow: 'none'
    })
  }),

  // cardWrap: {},

  cardMedia: ({ ownerState, theme }) => ({
    'transition': 'all 0.25s ease-in-out',
    'backgroundColor': 'inherit',
    'padding': 0,
    'width': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    '> *': {
      objectFit: 'cover',
      width: '100%',
      height: 'auto',
      aspectRatio: '16/9'
    },
    ...(ownerState?.variant === CardVariants.stat || ownerState?.variant === CardVariants.icon
      ? {
          img: {
            width: 40,
            height: 40
          }
          // paddingTop: theme.spacing(4)
        }
      : null)
  }),

  contentWrap: ({ ownerState, theme }) => ({
    // 'flex': 1,
    'width': '100%',
    'padding': 0,
    // TODO: MUI Override
    'paddingBottom': '0!important',

    ...(ownerState?.variant === CardVariants.stat &&
      {
        // padding: theme.spacing(4)
      }),
    'display': 'flex',
    'gap': theme.spacing(2),
    'flexDirection': 'column',
    '[class*=RichText-root]': {
      '> *': { margin: 0 }
    }
  }),

  title: ({ ownerState, theme }) => ({
    '.MuiTypography-root': {
      ...(ownerState?.variant === CardVariants.stat && {
        ...theme.typography.h3
        // color: 'var(--variant-highlight-color)'
      }),

      ...(ownerState?.variant === CardVariants.icon && {
        ...theme.typography.h4
      }),

      ...(ownerState?.variant === CardVariants.media ||
      ownerState?.variant === CardVariants.mediaCentered
        ? {
            ...theme.typography.h5
          }
        : undefined),

      ...(ownerState?.variant === CardVariants.blog && {
        ...theme.typography.h5
      }),
      ...(ownerState?.variant === CardVariants.blog
        ? {
            // May be different on other variants
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            minHeight: 52
          }
        : {}),
      ...(ownerState?.variant === CardVariants.media ||
      ownerState?.variant === CardVariants.mediaCentered
        ? {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            marginBottom: 0
          }
        : {})
    }
  }),
  bodyWrap: ({ ownerState, theme }) => ({
    minHeight: 64
  }),
  body: ({ ownerState, theme }) => ({
    '.MuiTypography-root': {
      // May be different on other variants
      ...(ownerState?.variant === CardVariants.media ||
      ownerState?.variant === CardVariants.mediaCentered ||
      ownerState?.variant === CardVariants.blog
        ? {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis'
          }
        : {})
    }
  }),

  subtitle: ({ ownerState, theme }) => ({
    '.MuiTypography-root': {
      ...(ownerState?.variant === CardVariants.icon && {
        ...theme.typography.h3,
        // TODO: Should not be overriding fonts here
        fontWeight: 400
      }),

      ...(ownerState?.variant === CardVariants.stat && {
        ...theme.typography.h4,
        // TODO: Should not be overriding fonts here
        fontWeight: 400
      }),

      ...(ownerState?.variant === CardVariants.media ||
      ownerState?.variant === CardVariants.mediaCentered
        ? {
            ...theme.typography.body1
          }
        : null),

      ...(ownerState?.variant === CardVariants.blog && {
        ...theme.typography.body1
      })
    }
  }),

  actionsWrap: ({ theme, ownerState }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    // margin: theme.spacing(0, -1),
    zIndex: 2,
    width: '100%',
    minHeight: 40,
    padding: 0,
    ...(ownerState?.variant === CardVariants.stat || ownerState?.variant === CardVariants.icon
      ? {
          justifyContent: 'center'
        }
      : null)
  }),

  link: {
    'position': 'absolute',
    'top': 0,
    'left': 0,
    'zIndex': 1,
    'width': '100%',
    'height': '100%',
    '&:hover': {
      '.MuiCardActionArea-focusHighlight': {
        opacity: 0
      }
    }
  }
};

const createVariants = (theme: Theme): ComponentsVariants['Card'] => [
  {
    props: {
      variant: CardVariants.media
    },
    style: {
      '[class*=Card-cardMedia]': {
        width: '100%',

        // 'overflow': 'hidden',
        img: {
          borderRadius: '8px',
          aspectRatio: '16/9',
          width: '100%',
          height: '100%'
        }
      },

      'backgroundColor': 'transparent',
      'overflow': 'visible',
      'padding': 0,
      'boxShadow': 'none',
      '[class*=actionsWrap]': {
        // margin: theme.spacing(0, -1)
      },
      '[class*=cardContent]': {
        display: 'none'
      }
    }
  },
  {
    props: {
      variant: CardVariants.mediaCentered
    },
    style: {
      'textAlign': 'center',
      '[class*=Card-cardMedia]': {
        width: '100%',

        // 'overflow': 'hidden',
        img: {
          borderRadius: '8px',
          aspectRatio: '16/9',
          width: '100%',
          height: '100%'
        }
      },
      '[class*=Card-actionsWrap]': {
        justifyContent: 'center'
      },

      'backgroundColor': 'transparent',
      'overflow': 'visible',
      'padding': 0,
      'boxShadow': 'none',
      '[class*=cardContent]': {
        display: 'none'
      }
    }
  },
  {
    props: {
      variant: CardVariants.default
    },
    style: {
      '[class*=Card-cardMedia]': {
        width: '100%',

        // 'overflow': 'hidden',
        img: {
          borderRadius: '8px',
          aspectRatio: '16/9',
          objectFit: 'contain',
          width: '100%',
          height: '100%'
        }
      },

      'backgroundColor': 'transparent',
      'overflow': 'visible',
      'padding': 0,
      'boxShadow': 'none',
      '[class*=actionsWrap]': {
        // margin: theme.spacing(0, -1)
      },
      '[class*=cardContent]': {
        display: 'none'
      }
    }
  },
  {
    props: {
      variant: CardVariants.icon
    },
    style: ({ theme, ownerState }) => ({
      'textAlign': 'center',

      'padding': theme.spacing(5, 3),
      'gap': theme.spacing(2),
      '[class*=actionsWrap]': {
        // margin: theme.spacing(0, -1)
      },
      '[class*=Card-cardMedia] img': {
        maxWidth: 96,
        // marginLeft: 'auto',
        // marginRight: 'auto',
        objectFit: 'contain',
        [theme.containerBreakpoints.up('lg')]: {
          '& > :is(img, svg)': {
            objectFit: 'contain'
          }
        }
      }
    })
  },
  {
    props: {
      variant: CardVariants.stat
    },
    style: ({ theme, ownerState }) => ({
      'textAlign': 'center',

      'padding': theme.spacing(5, 3),
      'gap': theme.spacing(2),
      '[class*=actionsWrap]': {
        // margin: theme.spacing(0, -1)
      },
      '[class*=Card-cardMedia] img': {
        maxWidth: 96,
        objectFit: 'contain',
        // marginLeft: 'auto',
        // marginRight: 'auto',

        [theme.containerBreakpoints.up('lg')]: {
          '& > :is(img, svg)': {
            objectFit: 'contain'
          }
        }
      }
    })
  },
  {
    props: {
      variant: CardVariants.logo
    },
    style: {
      'boxShadow': 'none',
      'background': 'transparent',
      '[class*=Card-contentWrap]': {
        // textAlign: 'center'
        display: 'none'
      },

      '[class*=Card-cardMedia]': {
        '& > :is(img, svg)': {
          objectFit: 'contain',
          width: '100%',
          height: '100%'
        }
      }
    }
  },

  {
    props: {
      variant: CardVariants.blog
    },
    style: {
      '[class*=Card-contentWrap]': {
        flex: 'unset'
      },
      'backgroundColor': 'transparent',
      'padding': 0,
      'overflow': 'visible',
      'gap': theme.spacing(2),
      'boxShadow': 'none',
      'borderRadius': 4,
      'alignItems': 'flex-start',
      '[class*=actionsWrap]': { alignItems: 'flex-end', flex: 1 },
      '[class*=Card-cardMedia] > *': {
        aspectRatio: '16/9',
        borderRadius: 4
      }
    }
  },
  {
    props: {
      variant: CardVariants.person
    },
    style: {
      'backgroundColor': 'transparent',
      '[class*=Card-contentWrap]': {
        containerType: 'inline-size',
        // flex: 'unset',
        width: '100%',
        backgroundColor: 'transparent'
      },

      'overflow': 'visible',
      'gap': theme.spacing(3),
      'boxShadow': 'none',
      'borderRadius': 4,
      'alignItems': 'flex-start',
      // 'backgroundColor': 'transparent',
      '[class*="Card-title"] .MuiTypography-root': {
        ...theme.typography.h4
      },
      '[class*=Card-cardMedia]': {
        'width': 80,
        'height': 80,
        // 'paddingTop': 'var(--grid-gap)',
        'aspectRatio': '1 / 1',
        '& > *': {
          borderRadius: '50%',
          objectFit: 'cover',
          objectPosition: 'top',
          aspectRatio: '1 / 1',
          width: '100%',
          display: 'inline-block'
          // overflow: 'hidden'
        }
      }
    }
  },
  {
    props: {
      variant: 'carouselHeroItem'
    },
    style: {
      'padding': 0,
      'display': 'grid',
      'gridTemplateColumns': '1fr 1fr',
      'alignItems': 'center',
      'backgroundColor': 'transparent',

      '[class*=Card-actionsWrap]': {
        gridColumn: 1,
        gridRow: 1
      },
      '[class*=Card-contentWrap]': {
        gridColumn: 1,
        gridRow: 1,
        containerType: 'inline-size',
        // flex: 'unset',
        width: '100%',
        backgroundColor: 'transparent'
      },

      'overflow': 'visible',
      'gap': theme.spacing(3),
      'boxShadow': 'none',
      'borderRadius': 4,
      // 'backgroundColor': 'transparent',
      '[class*="Card-title"] > .MuiTypography-root': {
        ...theme.typography.h4
      },
      '[class*=Card-cardMedia]': {
        'gridColumn': 2,
        'gridRowStart': 1,
        'gridRowEnd': 2,
        // 'width': 80,
        'height': 'auto',
        // 'paddingTop': 'var(--grid-gap)',
        'aspectRatio': '1 / 1',
        '& > *': {
          // borderRadius: '50%',
          objectFit: 'contain',
          objectPosition: 'top',
          aspectRatio: '1 / 1',
          width: '100%',
          display: 'inline-block',
          transform: 'translateY(50%) rotateZ(5deg)'
          // overflow: 'hidden'
        }
      }
    }
  }
];

export const cardTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Card: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    },
    MuiCardActions: {
      defaultProps: { disableSpacing: true }
    }
  }
});

export default cardTheme;
