import type { ComponentsOverrides, ComponentsVariants, ComponentsProps } from '@mui/material';

import type { ModuleIntegration_BaseFragmentFragment } from '@graphql-sdk/types';

export enum ModuleIntegrationVariants {
  default = 'default',
  supportingItemOnLeft = 'supportingItemOnLeft',
  supportingItemOnRight = 'supportingItemOnRight',
  supportingItemOnTop = 'supportingItemOnTop',
  supportingItemOnBottom = 'supportingItemOnBottom',
  simpleCentered = 'simpleCentered'
}

export interface ModuleIntegrationProps
  extends Omit<ModuleIntegration_BaseFragmentFragment, 'variant'> {
  variant: ModuleIntegrationVariants;
}

export interface ModuleIntegrationOwnerState extends ModuleIntegrationProps {}

interface ModuleIntegrationClasses {
  root: string;
  introTextGrid: string;
  introText: string;
  contentOuterGrid: string;
  mainContentWrap: string;
  sideContentWrap: string;
  media: string;
  content: string;
  background: string;
  overline: string;
  title: string;
  subtitle: string;
  body: string;
  mediaItems: string;
  actionsWrap: string;
  action: string;
}

export declare type ModuleIntegrationClassKey = keyof ModuleIntegrationClasses;

declare module '@mui/material/styles' {
  export interface ComponentNameToClassKey {
    ModuleIntegration: ModuleIntegrationClassKey;
  }

  export interface ComponentsPropsList {
    ModuleIntegration: ModuleIntegrationProps;
  }
}

declare module '@mui/material/styles' {
  interface Components {
    ModuleIntegration?: {
      defaultProps?: ComponentsProps['ModuleIntegration'];
      styleOverrides?: ComponentsOverrides<Theme>['ModuleIntegration'];
      variants?: ComponentsVariants['ModuleIntegration'];
    };
  }
}
