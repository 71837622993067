import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { ModuleIntegrationVariants } from './ModuleIntegration.types';

const defaultProps: ComponentsProps['ModuleIntegration'] = {
  variant: ModuleIntegrationVariants.supportingItemOnRight
};

const styleOverrides: ComponentsOverrides<Theme>['ModuleIntegration'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 'var(--section-padding)',
    paddingBottom: 'var(--section-padding)'
  })
};

const createVariants = (theme: Theme): ComponentsVariants['ModuleIntegration'] => [
  {
    props: { variant: 'calculatorHSASavings' },
    style: {
      '[class*=contentGrid]': {
        minHeight: 839
      }
    }
  },
  {
    props: { variant: 'calculatorFSASavings' },
    style: {
      '[class*=contentGrid]': {
        minHeight: 564
      }
    }
  },
  {
    props: { variant: 'calculatorHSAContribution' },
    style: {
      '[class*=contentGrid]': {
        minHeight: 506
      }
    }
  },
  {
    props: { variant: 'calculatorOptimalHSAContribution' },
    style: {
      '[class*=contentGrid]': {
        minHeight: 676
      }
    }
  }
];

export const moduleintegrationTheme = (theme: Theme): ThemeOptions => ({
  components: {
    ModuleIntegration: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default moduleintegrationTheme;
