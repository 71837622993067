import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

import type { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['HtmlSnippet'] = {
  iFrameWidth: '100%',
  iFrameHeight: '100%'
};

const styleOverrides: ComponentsOverrides<Theme>['HtmlSnippet'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    containerType: 'inline-size',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    gap: theme.spacing(2),

    padding: 'calc(var(--grid-gap) * 2) 0'
  }),

  contentOuterGrid: {
    '> *': {
      gridColumnStart: 'auto'
    }
  },

  introText: { gridColumn: 'content-start / content-end' },

  introTextGrid: { marginBottom: 'calc(2 * var(--grid-gap))' },

  mainContentWrap: ({ ownerState }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    position: 'relative',
    width: '100%',
    margin: 'auto',

    ...(!!ownerState?.showFullWidth
      ? { gridColumnStart: 'full-start', gridColumnEnd: 'full-end' }
      : {
          gridColumnStart: 'content-start',
          gridColumnEnd: 'content-end'
        }),

    iframe: {
      minWidth: '100%',
      width: '100%',
      border: 0
    }
  })
};

const createVariants = (theme: Theme): ComponentsVariants['HtmlSnippet'] => [];

export const iframeTheme = (theme: Theme): ThemeOptions => ({
  components: {
    HtmlSnippet: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default iframeTheme;
