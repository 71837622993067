import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

import { BlockVariants } from './Block.types';

const defaultProps: ComponentsProps['Block'] = {
  variant: BlockVariants.supportingItemOnRight
};
// const isPortrait = (aspectRatio: string) => {
//   if (!aspectRatio) return false;
//   const [width, height] = aspectRatio.split('/');
//   return Number(height) > Number(width);
// };
const styleOverrides: ComponentsOverrides<Theme>['Block'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    'containerType': 'inline-size',
    'position': 'relative',
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'gap': theme.spacing(2),
    ...(!!ownerState?.hideOnDesktop && {
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    }),
    ...(!!ownerState?.hideOnMobile && {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }),
    ...(!!ownerState?.hideOnTablet && {
      [theme.breakpoints.only('md')]: {
        display: 'none'
      }
    }),
    '[class*=Block-"Background-root"] + [class*=Block-Section-contentWrap] & [class*=Block-mainContentWrap]':
      {
        padding: 'calc(var(--grid-gap) * 2)',
        paddingTop: 0
      },
    ...(!!ownerState?.overlap
      ? {
          'marginTop': '0!important',
          'paddingTop': '0!important',
          [theme.containerBreakpoints.up('md')]: {
            '[class*=Block-mainContentWrap]': {
              position: 'sticky',
              top: 0,
              paddingTop: `calc(var(--section-padding) + ${theme.spacing(5)})`,
              alignSelf: 'flex-start'
            }
          },
          '&:before': {
            zIndex: '-1',
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'var(--section-padding)',
            background: `var(--mui-palette-prev-color)`
          }
        }
      : {}),
    // TODO: Update to check if within a section
    // padding: theme.spacing(0, 4)
    // margin: theme.spacing(0, -4),
    '[class*=Form-root]': {
      '.MuiTypography-h1': { ...theme.typography.h3 },
      '.MuiTypography-h2': { ...theme.typography.h4 },
      '.MuiTypography-h3': { ...theme.typography.h5 },
      '.MuiTypography-h4': { ...theme.typography.h6 },
      '.MuiTypography-h5': { ...theme.typography.h6 }
    }
  }),
  sideContentWrap: ({ ownerState }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    ...(ownerState?.aspectRatio
      ? {
          maxHeight: 'min(70vh, 720px)',
          aspectRatio: ownerState?.aspectRatio ? ownerState?.aspectRatio : null,
          margin: 'auto'
        }
      : { img: { aspectRatio: '4/3', width: '100%' } })
  }),

  // introTextGrid: : {},
  media: ({ ownerState }) => ({
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: 16
  }),
  introText: { gridColumn: 'content-start / content-end' },

  contentOuterGrid: {
    '> *': {
      // gridColumnStart: 'auto'
    }
  },

  overline: {
    color: 'var(--mui-palette-overline)'
  },

  // title: {},

  // subtitle: {},

  body: ({ theme }) => ({
    ul: {
      // 'listStyleImage': "url('/static/images/icons/check.svg')",
      '>li': {
        paddingLeft: theme.spacing(1)
      },
      'li::marker': {
        content: "'✓'",
        fontSize: '21px',
        color: 'var(--mui-palette-text-primary)'
      }

      // 'li:before': {
      // }
    }
  }),

  content: {
    display: 'flex',
    flexDirection: 'column'
  },

  mainContentWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center'
  },

  // mediaItems: : {},

  actionsWrap: ({ theme, ownerState }) => ({
    // margin: theme.spacing(0, -1),
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: 'calc(var(--grid-gap) * 2)',
    alignItems: 'center',
    [theme.containerBreakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  }),
  disclaimerText: ({ theme }) => ({
    textAlign: 'center'
  })
  // action: : {}
};

const createVariants = (theme: Theme): ComponentsVariants['Block'] => [
  {
    props: {
      variant: BlockVariants.supportingItemOnRight
    },
    style: {
      '[class*=Block-mainContentWrap]': {
        gridRow: 2,
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('sm')]: {
          gridRow: 1,
          gridColumnStart: 'content-start',
          gridColumnEnd: 'content-half',
          paddingRight: 'calc(var(--grid-gap) * 2)'
        }
      },

      '[class*=Block-sideContentWrap]': {
        gridRow: 1,
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('sm')]: {
          gridColumnStart: 'content-half',
          gridColumnEnd: 'content-end'
        }
      }
    }
  },
  {
    props: {
      variant: BlockVariants.default
    },
    style: {
      '[class*=Block-mainContentWrap]': {
        gridRow: 2,
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridRow: 1,
          gridColumnStart: 'content-start',
          gridColumnEnd: 'content-half',
          paddingRight: 'calc(var(--grid-gap) * 2)'
        }
      },

      '[class*=Block-sideContentWrap]': {
        gridRow: 1,
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridColumnStart: 'content-half',
          gridColumnEnd: 'content-end'
        }
      }
    }
  },
  // {
  //   props: {
  //     variant: BlockVariants.supportingItemOnRightFullBleed
  //   },
  //   style: {
  //     '[class*=Block-mainContentWrap]': {
  //       gridRow: 2,
  //       gridColumnStart: 'content-start',
  //       gridColumnEnd: 'content-end',
  //       [theme.containerBreakpoints.up('md')]: {
  //         gridRow: 1,
  //         gridColumnStart: 'content-start',
  //         gridColumnEnd: 'content-half',
  //         paddingRight: 'calc(var(--grid-gap) * 2)'
  //       }
  //     },

  //     '[class*=Block-sideContentWrap]': {
  //       gridRow: 1,
  //       gridColumnStart: 'full-start',
  //       gridColumnEnd: 'full-end',

  //       [theme.containerBreakpoints.up('md')]: {
  //         gridColumnStart: 'content-half',
  //         gridColumnEnd: 'full-end'
  //       }
  //     }
  //   }
  // },
  {
    props: {
      variant: BlockVariants.supportingItemOnLeft
    },
    style: {
      '[class*=Block-mainContentWrap]': {
        gridRow: 2,
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridRow: 1,
          gridColumnStart: 'content-half',
          gridColumnEnd: 'content-end',
          paddingLeft: 'calc(var(--grid-gap) * 2)'
        }
      },

      '[class*=Block-sideContentWrap]': {
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.containerBreakpoints.up('md')]: {
          gridColumnEnd: 'content-half'
        }
      }
    }
  },

  // {
  //   props: {
  //     variant: BlockVariants.supportingItemOnLeftFullBleed
  //   },
  //   style: {
  //     '[class*=Block-mainContentWrap]': {
  //       gridRow: 2,
  //       gridColumnStart: 'content-start',
  //       gridColumnEnd: 'content-end',

  //       [theme.containerBreakpoints.up('md')]: {
  //         gridRow: 1,
  //         gridColumnStart: 'content-half',
  //         gridColumnEnd: 'content-end',
  //         paddingLeft: 'calc(var(--grid-gap) * 2)'
  //       }
  //     },

  //     '[class*=Block-sideContentWrap]': {
  //       gridColumnStart: '1',
  //       gridColumnEnd: '-1',

  //       [theme.containerBreakpoints.up('md')]: {
  //         gridColumnEnd: 'content-half'
  //       }
  //     }
  //   }
  // },

  {
    props: {
      variant: BlockVariants.supportingItemOnTop
    },
    style: {
      'textAlign': 'center',
      '[class*=Block-mainContentWrap]': {
        'gridRow': 2,
        'gridColumnStart': 'content-start',
        'gridColumnEnd': 'content-end',

        '& *': {
          alignSelf: 'center'
        }
      },

      '[class*=Block-sideContentWrap]': {
        gridColumn: 'content-start/content-end',
        gridRow: 1
      }
    }
  },
  {
    props: {
      variant: BlockVariants.supportingItemOnBottom
    },
    style: {
      'textAlign': 'center',
      '[class*=Block-mainContentWrap]': {
        'gridRow': 1,
        'gridColumnStart': 'content-start',
        'gridColumnEnd': 'content-end',
        '& *': {
          alignSelf: 'center'
        }
      },

      '[class*=Block-sideContentWrap]': {
        'gridColumn': 'content-start/content-end',
        'gridRow': 2,
        '[class*=Block-media]': {
          // aspectRatio: '21/9'
        }
      }
    }
  },
  {
    props: {
      variant: BlockVariants.simpleCentered
    },
    style: {
      'textAlign': 'center',
      '[class*=Block-mainContentWrap]': {
        'gridRow': 1,
        'gridColumnStart': 'content-start',
        'gridColumnEnd': 'content-end',
        '& *': {
          alignSelf: 'center'
        }
      },

      '[class*=Block-sideContentWrap]': {
        'gridColumn': 'content-start/content-end',
        'gridRow': 2,
        '[class*=Block-media]': {
          // aspectRatio: '21/9'
        }
      }
    }
  }
];

export const blockTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Block: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default blockTheme;
