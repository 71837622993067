import {
  type Theme,
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants
} from '@mui/material/styles';

const defaultProps: ComponentsProps['CarouselHero'] = {};

const styleOverrides: ComponentsOverrides<Theme>['CarouselHero'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    'containerType': 'inline-size',
    // 'display': 'flex',

    'overflow': 'hidden',
    // 'flexDirection': 'column',
    'width': '100%',
    // 'position': 'relative',
    // 'padding': 0,
    // 'minHeight': '720px',
    // 'maxHeight': '70vh',
    // 'justifyContent': 'center',
    // 'alignItems': 'center',
    '&': {
      // Swiper Overrides
      '--swiper-navigation-color': `white`,
      '--swiper-pagination-color': `var(--swiper-theme-color)`,
      '--swiper-preloader-color': `var(--swiper-theme-color)`,
      '--swiper-pagination-fraction-color': 'inherit',
      '--swiper-pagination-progressbar-bg-color': 'rgba(0, 0, 0, 0.25)',
      // '--swiper-pagination-bullet-inactive-color': `var(--swiper-theme-color)`,
      '--swiper-scrollbar-bg-color': 'rgba(0, 0, 0, 0.1)',
      '--swiper-scrollbar-drag-bg-color': 'rgba(0, 0, 0, 0.5)',
      '--swiper-navigation-size': '40px',
      '--swiper-pagination-bullet-inactive-color': 'var(--mui-palette-white-main)',
      '--swiper-pagination-bullet-inactive-opacity': 1,
      '--swiper-navigation-sides-offset': theme.spacing(-8),
      [theme.breakpoints.down('md')]: {
        '--swiper-navigation-sides-offset': theme.spacing(-3),
        '--swiper-navigation-size': '24px'
      },
      /* 
              --swiper-wrapper-transition-timing-function: initial;

              --swiper-navigation-top-offset: 100%;
             

              --swiper-pagination-color: currentColor; //var(--current-color-text); //var(--swiper-theme-color);
              --swiper-pagination-left: auto;
              --swiper-pagination-right: calc(var(--grid-gap) / 2);
              --swiper-pagination-bottom: calc(var(--grid-gap) / 2);
              --swiper-pagination-top: auto;
              --swiper-pagination-fraction-color: inherit;
              --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
              --swiper-pagination-progressbar-size: calc(var(--grid-gap) / 4);
              --swiper-pagination-bullet-size: calc(var(--grid-gap) / 2);
              --swiper-pagination-bullet-width: calc(var(--grid-gap) / 2);
              --swiper-pagination-bullet-height: calc(var(--grid-gap) / 2);
              --swiper-pagination-bullet-border-radius: 50%;
              --swiper-pagination-bullet-inactive-color: currentColor; //var(--swiper-theme-color);
              --swiper-pagination-bullet-inactive-opacity: 0.2;
              --swiper-pagination-bullet-opacity: 1;
              --swiper-pagination-bullet-horizontal-gap: calc(var(--grid-gap) / 4);
              --swiper-pagination-bullet-vertical-gap: calc(var(--grid-gap) / 4);

              --swiper-scrollbar-border-radius: calc(var(--grid-gap) / 2);
              --swiper-scrollbar-top: auto;
              --swiper-scrollbar-bottom: calc(var(--grid-gap) / 4);
              --swiper-scrollbar-left: auto;
              --swiper-scrollbar-right: calc(var(--grid-gap) / 4);
              --swiper-scrollbar-sides-offset: 1%;
              --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
              --swiper-scrollbar-drag-bg-color: rgba(0, 0, 0, 0.5);
              --swiper-scrollbar-size: calc(var(--grid-gap) / 4); */

      '.swiper': {
        overflow: 'visible',
        // paddingBottom: theme.spacing(6),
        ...(ownerState?.itemsVariant === 'logo' &&
          {
            // paddingBottom: theme.spacing(6)
          })
      },
      '.swiper-slide': {
        'width': '100%',
        'height': 'auto',
        'alignSelf': 'stretch',
        'display': 'flex',
        'flexDirection': 'column',
        '> *': {
          width: '100%'
        },
        'transition': '.25s ease-in-out',
        [theme.breakpoints.down('md')]: {
          'alignSelf': 'unset',
          'opacity': '0!important',
          '&.swiper-slide-active': {
            opacity: '1!important'
          }
        }
        // 'opacity': '0!important',
        // '&.swiper-slide-active': {
        //   opacity: '1!important'
        // }
      },
      '.swiper:not(.swiper-initialized) .swiper-wrapper': {
        '.swiper-slide': {
          opacity: '0'
        },
        '.swiper-slide:first-child': {
          opacity: '1!important'
        }
      },
      '.swiper-wrapper': {
        alignItems: 'center'
        // overflow: 'visible'
      },
      '.swiper-pagination': {
        // position: 'relative',
        // marginTop: '24px'
      },
      '.swiper-pagination-bullet': {
        'transform': 'scale(1)',
        '&.swiper-pagination-bullet-active': { transform: 'scale(1.25)' }
      },
      ':is(.swiper-button-prev, .swiper-button-next)': {
        // aspectRatio: '1/1'
        // width: 'var(--swiper-navigation-size)'
        // padding: 'var(--swiper-navigation-size)'
      }
    }
  }),

  swiperWrap: {
    // padding: 'var(--section-padding) 0 calc(2 * var(--section-padding))',
    // Change this to make it full bleed on the grid
    gridColumn: 'content-start/content-end'
  },

  swiperInnerWrap: {
    'position': 'relative',

    '.swiper': {
      // Allows for placement of the buttons below the CarouselHero if needed.
      // 'overflowY': 'unset',
      // 'width': 'calc(100% - (var(--grid-margin) / 2px))',
      '&.swiper-grid': {
        width: '100%'
      }
    }
  },

  item: {
    // overflow: 'unset',
    // position: 'relative',
    // zIndex: 20
  },

  contentGrid: {},
  disclaimerText: ({ theme }) => ({
    textAlign: 'center'
  })
};

const createVariants = (theme: Theme): ComponentsVariants['CarouselHero'] => [];

const CarouselHeroTheme = (theme: Theme): ThemeOptions => ({
  components: {
    CarouselHero: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default CarouselHeroTheme;
