import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Tabs'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Tabs'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    'backgroundColor': 'unset',
    '[class*=Tabs-background]': {
      backgroundColor: 'unset'
    },
    'containerType': 'inline-size',
    'position': 'relative',
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'padding': `var(--section-padding) 0`,
    '.MuiTabs-flexContainer': {
      gap: theme.spacing(0.5)
    },
    '.MuiTabs-indicator': {
      display: 'none'
    },
    '.MuiTab-root': {
      'flex': 1,

      'backgroundColor': `color-mix(in srgb, ${theme.vars.palette.background.tab}, transparent 70%)`,
      'color': theme.vars.palette.common.black,
      'borderRadius': '8px 8px 0 0',
      '&.Mui-selected': {}
    },

    '.Mui-selected': {
      opacity: 1,
      fontWeight: 700,
      color: 'currentColor',
      backgroundColor: theme.vars.palette.background.tab
    },
    // TODO: Review and maybe create as new variants
    '& [class*=Card-root]': {
      'backgroundColor': 'transparent!important',
      'boxShadow': 'none',
      '.MuiTypography-body1': {
        color: `${theme.vars.palette.text.primary}`
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0)
      }
    },

    '.MuiTypography-h1': { ...theme.typography.h3 },
    '.MuiTypography-h2': { ...theme.typography.h4 },
    '.MuiTypography-h3': { ...theme.typography.h5 },
    '.MuiTypography-h4': { ...theme.typography.h6 },
    '.MuiTypography-h5': { ...theme.typography.h6 }
  }),

  // introTextGrid: : {},

  introText: { gridColumn: 'content-start / content-end' },

  contentOuterGrid: {
    '> *': {
      gridColumnStart: 'auto'
    }
  },
  tabsWrap: ({ theme }) => ({
    gridColumnStart: 'content-start',
    gridColumnEnd: 'content-end',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      gridColumnStart: 'full-start',
      gridColumnEnd: 'full-end'
    }
  }),
  // tabsContext: {},
  tabListWrap: ({ theme }) => ({
    // 'gridColumnStart': 'content-start',
    // 'gridColumnEnd': 'content-end',
  }),

  detailsWrap: ({ theme }) => ({
    'backgroundColor': theme.vars.palette.background.tab,
    'display': 'block!important',
    'gridColumnStart': 'content-start',
    'gridColumnEnd': 'content-end',
    'padding': theme.spacing(6, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2)
    },
    '&[hidden]': {
      visibility: 'hidden',
      position: 'absolute'
    }
  })
  // details: {}
};

const createVariants = (theme: Theme): ComponentsVariants['Tabs'] => [];

export const TabsTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Tabs: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default TabsTheme;
