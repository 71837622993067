import {
  type Theme,
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants,
  alpha
} from '@mui/material/styles';
import { FormVariants } from './Form.types';

const defaultProps: ComponentsProps['Form'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Form'] = {
  root: ({ theme, ownerState }) => ({
    margin: 'auto',
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    containerType: 'inline-size',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  }),
  mainContentWrap: ({ theme }) => ({
    maxWidth: '100%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 3)
    }
  }),
  recaptchaWrap: {
    order: 1,
    marginBottom: 'var(--grid-gap)!important',
    display: 'flex',
    justifyContent: 'center'
  },
  formDisclaimer: {
    marginTop: 'var(--grid-gap)!important',
    order: 2
  },
  formWrap: ({ theme, ownerState }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'space-between',
    'marginTop': theme.spacing(2),
    '& form.mktoForm': {
      'display': 'contents',
      '*:is( .mktoPlaceholder, .mktoOffset, .mktoClear, .mktoGutter, .mktoButtonWrap,  .mktoClear, .mktoGutter, .mktoOffset)':
        {
          display: 'contents'
        },

      '.mktoFormRow': {
        display: 'flex',
        flexDirection: 'row',
        gap: 'var(--grid-gap)',
        [theme.breakpoints.down('md')]: {
          gap: theme.spacing(0),
          flexDirection: 'column'
        }
      },

      '.mktoFormCol': {
        float: 'unset',
        width: '100%',
        marginBottom: 'var(--grid-gap) !important'
      },

      '.mktoError': {
        'bottom': 'unset!important',
        'left': '8px !important',
        'right': 'unset !important',
        'top': '100% !important',
        // TODO: Use typography
        'fontSize': '12px',
        '*': {
          backgroundColor: 'transparent',
          backgroundImage: 'unset',
          border: 'unset !important',
          textShadow: 'unset',
          boxShadow: 'unset',
          color: theme.palette.error.main,
          fontSize: 'inherit',
          padding: 0,
          margin: 0,
          display: 'inline !important'
        }
      },

      '*:is(label)': {
        display: 'none'
      },

      '.mktoFieldWrap': {
        order: 0,
        float: 'unset',
        width: '100%',
        height: '100%',
        position: 'relative'
      },

      '.mktoButtonRow': {
        'width': '100%',
        'margin': 'auto',
        'order': 2,
        'display': 'flex',
        'justifyContent': 'center',
        '.mktoError': {
          left: '0px!important',
          width: '100%',
          textAlign: 'center'
        }
      },

      '*:is(select, input, textarea)': {
        ...theme.typography.body1,
        'height': 'auto',
        'width': '100% !important',
        'maxWidth': '100%',

        'padding': theme.spacing(1, 2),
        'border': 'unset',
        'borderBottomStyle': `solid`,
        'borderBottomWidth': `2px`,
        'borderRadius': 0,
        'borderColor': theme.vars.palette.background.lightOne,
        'backgroundColor': theme.vars.palette.background.lightThree,

        '&[aria-invalid=true]': {
          borderBottomColor: theme.palette.error.main
        },

        '&::placeholder': {
          opacity: 0.7
        },

        '&:hover': {
          borderColor: theme.vars.palette.background.lightOne
          // boxShadow: theme.shadows[1]
        },

        '&:focus': {
          borderColor: theme.vars.palette.background.lightOne
          // boxShadow: theme.shadows[1]
        },

        '&:focus-visible': {
          outline: `${theme.vars.palette.background.lightOne} auto 0px`
        }
      },

      'textarea': {
        height: 300,
        maxHeight: '80vh'
      },

      'select': {
        'appearance': 'none',
        'backgroundImage':
          'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gXAogICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMS45OTc1IDAuNzM3NTQ5TDguNSA2LjQ2MjU1TDE1LjAwMjUgMC43Mzc1NDlMMTcgMi41MDAwNUw4LjUgMTBMMCAyLjUwMDA1TDEuOTk3NSAwLjczNzU0OVoiIGZpbGw9IiNBNEE0QTQiLz4gXAogICAgICAgICAgICAgICAgICAgIDwvc3ZnPg==)',
        'backgroundPosition': 'right center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 17,
        'backgroundOrigin': 'content-box',

        '&.is-placeholder': {
          color: alpha(theme.palette.primary.contrastText, 0.7)
        }
      },

      'button[type="submit"]:is(:not(.MuiButtonBase-root))': {
        ...theme.typography.body1,

        'backgroundColor': theme.vars.palette.primary.main,
        'color': theme.vars.palette.primary.contrastText,
        'border': `0`,
        'padding': theme.spacing(1, 3), // Substract border
        'borderRadius': theme.shape.borderRadius,
        'textDecoration': 'none',
        'width': 'unset',
        'margin': '0 auto!important',
        'display': 'block',

        '&:hover': {
          // borderColor: theme.vars.palette.primary.contrastText,
          backgroundColor: theme.vars.palette.primary.dark
        },

        '&:active': {
          backgroundColor: theme.vars.palette.primary.dark
          // borderColor: theme.vars.palette.primary.contrastText
        }
      }
    }
  })
};

const createVariants = (theme: Theme): ComponentsVariants['Form'] => [
  {
    props: { variant: FormVariants.sidebarNewsletter },
    style: {
      background: theme.vars.palette.background.lightThree,
      padding: theme.spacing(6, 3)
    }
  },
  {
    props: { variant: FormVariants.default },
    style: {
      // 'minHeight': 536,
      '[class*=mainContentWrap]': {
        background: theme.vars.palette.common.white,
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2)
        },
        boxShadow: '0px 0px 64px 20px rgba(0, 0, 0, 0.06)'
      },

      '[class*=Text-root] ': {
        'margin': 0,
        '[class*=Text-title]': {
          ...theme.typography.display2
        },
        '[class*=Text-subtitle]': {
          ...theme.typography.body1
        }
      }
    }
  }
];

export const formTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Form: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default formTheme;
