import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['PageResource'] = {};

const styleOverrides: ComponentsOverrides<Theme>['PageResource'] = {
  root: ({ theme }) => ({
    '& > :is([class*=Collection-root],[class*=Section-root],[class*=Block-root],[class*=Carousel-root])':
      {
        paddingTop: 'var(--section-padding)',
        paddingBottom: 'var(--section-padding)'
      },

    '& > :is([class*=Form-root])': {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7)
    },

    '& > :is([class*=Text-root])': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    },

    '[class*=contentWrap]': {
      'display': 'contents',

      '> *:is([class*=Accordion-root],[class*=Collection-root],[class*=Quote-root],[class*=Section-root],[class*=Block-root],[class*=Carousel-root],[class*=Text-root])':
        {
          gridColumnStart: 'content-start',
          gridColumnEnd: 'content-end',
          [theme.breakpoints.up('md')]: {
            gridColumnStart: 'three-start',
            gridColumnEnd: 'ten-end'
          },
          [theme.breakpoints.up('lg')]: {
            gridColumnStart: 'three-start',
            gridColumnEnd: 'ten-end'
          }
        }
    },

    '[class*=body]': {
      '[class*=RichText-embeddedRoot]': {
        display: 'contents'
      },

      '& > *, [class*=RichText-embeddedRoot] > *': {
        // maxWidth: '680px',
        // marginBottom: '0!important',
        gridColumnStart: 'content-start',
        gridColumnEnd: 'content-end',

        [theme.breakpoints.up('md')]: {
          gridColumnStart: 'one-end',
          gridColumnEnd: 'seven-end'
        },

        [theme.breakpoints.up('lg')]: {
          gridColumnStart: 'two-start',
          gridColumnEnd: 'eleven-end'
        }
      }
    }
  }),

  contentOuterGrid: ({ theme }) => ({
    containerType: 'inline-size',
    rowGap: theme.spacing(2),
    padding: theme.spacing(6, 0)
  }),

  contentWrap: ({ theme }) => ({
    'display': 'contents',
    '> *': {
      gridColumnStart: 'content-start',
      gridColumnEnd: 'content-end'
      // [theme.breakpoints.up('md')]: {
      //   gridColumnStart: 'three-start',
      //   gridColumnEnd: 'ten-end'
      // },
      // [theme.breakpoints.up('lg')]: {
      //   gridColumnStart: 'three-start',
      //   gridColumnEnd: 'ten-end'
      // }
    }
  }),

  featuredMedia: {
    width: '100%',
    borderRadius: 16
  },

  shareLinks: ({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: 'var(--grid-gap)'
  }),

  shareLink: ({ theme }) => ({
    'width': 'unset',
    'padding': 0,
    'gap': 'var(--grid-gap)',

    '& svg': {
      width: 'var(--grid-gap)',
      height: 'var(--grid-gap)'
    },

    '& .MuiTypography-root': {
      display: 'none'
    },

    [theme.containerBreakpoints.up('lg')]: {
      'gap': 'var(--grid-gap)',
      '& .MuiTypography-root': {
        ...theme.typography.bodySmall,
        display: 'block'
      }
    }
  }),

  body: ({ theme }) => ({
    'display': 'contents',
    '& > *:not(div)': {
      // maxWidth: '680px',
      marginBottom: '0!important',
      gridColumnStart: 'content-start',
      gridColumnEnd: 'content-end'
    },

    '& > div': {
      gridColumnStart: 'content-start',
      gridColumnEnd: 'content-end'
      // '&:is([class*=Text-root])': {
      // gridColumnStart: 'three-start',
      // gridColumnEnd: 'ten-end'
      // }
    },
    '[class*="Media-root"]': {
      borderRadius: 16
    }
  }),

  authorWrap: ({ theme }) => ({
    gridColumnStart: 'full-start',
    gridColumnEnd: 'full-end',
    padding: theme.spacing(7, 0),
    background: theme.vars.palette.background.lightThree
  }),

  relatedItemsWrap: {
    '& > *': {
      gridColumn: 'full-start / full-end'
    }
  }
};

const createVariants = (theme: Theme): ComponentsVariants['PageResource'] => [];

export const pageResourceTheme = (theme: Theme): ThemeOptions => ({
  components: {
    PageResource: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default pageResourceTheme;
