import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Footer'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Footer'] = {
  root: ({ theme, ownerState }) => ({
    ...theme.mixins.applyColorScheme({ ownerState, theme }),
    position: 'relative',
    padding: theme.spacing(10, 0)
  }),

  introContentsWrap: ({ theme }) => ({
    // marginBottom: 'var(--section-padding)'
  }),

  // introContent: {},

  contentOuterGrid: ({ theme }) => ({
    'rowGap': theme.spacing(2),
    '& a': {
      whiteSpace: 'nowrap'
    }
  }),

  logoRoot: ({ theme }) => ({
    gridColumn: 'content-start / content-half',
    gridRow: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 120,
    height: 'auto',
    display: 'block',

    [theme.breakpoints.up('md')]: {
      gridColumn: 'content-start / span 2'
    }
  }),

  featuredActionsWrap: ({ theme }) => ({
    gridColumn: 'content-start / content-end',
    gridRow: 3,
    alignSelf: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      gridRow: 1,
      gridColumn: 'content-half / content-end',
      justifySelf: 'flex-end'
    }
  }),

  footerMenuNav: ({ theme, ownerState }) => ({
    gridRow: 2,
    gridColumnStart: 'content-start',
    gridColumnEnd: 'content-end'
  }),

  footerMenuNavItems: ({ theme, ownerState }) => ({
    display: 'grid',
    alignItems: 'flex-start',
    padding: 0,
    position: 'unset',
    flexDirection: 'row',
    width: '100%',
    margin: 'auto',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, max-content)',
      height: '100%'
    },

    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(5),
      gridTemplateColumns: 'repeat(4, max-content)',
      height: '100%'
    }
  }),

  legalLinkWrap: ({ theme }) => ({
    width: 'auto'
  }),

  footerMenuNavItem: ({ theme }) => ({
    padding: 0,
    position: 'unset',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      height: '100%'
    }
  }),

  socialLinks: ({ theme }) => ({
    gridRow: 6,
    gridColumnStart: 'content-start',
    gridColumnEnd: 'content-end',

    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    gap: 'var(--grid-gap)',
    justifySelf: 'flex-start',

    [theme.breakpoints.up('md')]: {
      justifySelf: 'center',
      gridColumnStart: 'content-half',
      gridColumnEnd: 'content-end',
      justifyContent: 'flex-end',
      width: '100%',
      gridRow: 4
    }
  }),

  socialLink: ({ theme }) => ({
    'backgroundColor': theme.vars.palette.primary.main,
    'height': 30,
    'width': 30,

    '&:hover': {
      backgroundColor: theme.vars.palette.primary.main
    }
  }),

  legalSection: ({ theme }) => ({
    'display': 'contents',

    '& .MuiTypography-root': {
      ...theme.typography.bodySmall,
      'margin': 0,
      'a:not([class*=Button])': {
        color: 'white'
      }
    }
  }),

  copyrightDivider: ({ theme }) => ({
    gridColumn: 'content-start/content-end',
    gridRow: 4,

    [theme.breakpoints.up('md')]: {
      gridRow: 3
    }
  }),

  copyrightDisclaimerWrap: ({ theme }) => ({
    gridRow: 5,
    gridColumnStart: 'content-start',
    gridColumnEnd: 'content-end',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      gridRow: 4,
      gridColumnStart: 'content-start',
      gridColumnEnd: 'content-half'
    }
  }),

  legalLinks: ({ theme }) => ({
    'gridColumnStart': 'content-start',
    'gridColumnEnd': 'content-end',
    'display': 'inline-flex',
    'height': '100%',
    'gridRow': 7,
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    'marginLeft': theme.spacing(-2),

    '> *:not(:last-child)': {
      '&:before': {
        content: "'/'",
        color: theme.vars.palette.text.primary,
        display: 'block',
        position: 'absolute',
        right: '0px',
        top: '50%',
        transform: 'translate(50%,-50%)'
      }
    },

    [theme.breakpoints.up('sm')]: {
      gridColumn: 'content-start / content-end',
      justifySelf: 'center'
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(0),
      alignItems: 'center',
      flexDirection: 'row',
      gridRow: 4
    }
  }),

  disclaimerWrap: ({ theme }) => ({
    'gridRow': 8,
    'gridColumnStart': 'full-start',
    'gridColumnEnd': 'full-end',

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
      gridRow: 5
    },

    '& [class*=disclaimer] > *': {
      gridColumnStart: 'content-start',
      gridColumnEnd: 'content-end'
    }
  })
};

const createVariants = (_theme: Theme): ComponentsVariants['Footer'] => [];

export const footerTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Footer: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default footerTheme;
